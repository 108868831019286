const projectManage = () => import("../views/dataManage/index");
const projectList = () => import("../views/project/projectList");
const projectData = () => import("../views/project/projectData");

export default {
    path: "/project",
    name: "projectManage",
    meta: {
        icon: "iconfont iconxiangmuguanli",
        title: "项目管理",
        lang_key: "nav.project_manage",
        // authority: ["0", "1", "2", "3"]
    },
    component: projectManage,
    children: [
        {
            path: "/project",
            redirect: "/project/list"
        },
        {
            path: "/project/list",
            name: "projectList",
            component: projectList
        },
        {
            path: "/project/data",
            name: "projectData",
            meta: {
                icon: "fa fa-dashboard fa-tachometer-alt",
                title: "项目数据",
                lang_key: "nav.project_manage",
                authority: ["1", "2"]
            },
            component: projectData
        }
    ]
}