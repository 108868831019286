import zhLocale from "element-ui/lib/locale/lang/zh-CN";

export default {
  nav: {
    summary: "摘要",
    data_manage: "数据管理",
    project_manage: "项目管理",
    MRI_analysis: "影像分析",
    PET_analysis: "PET分析",
    cohort: "群组分析",
    rule: "规则管理",
    series_manage: "序列管理",
    tag_manage: "标签管理",
    tool_download: "工具下载",
    service: "服务应用",
    user_info: "个人资料",
    reset_password: "修改密码",
    department: "实验室管理",
    doctor: "首席研究员管理",
    student: "研究员管理",
    log: "操作日志",
  },
  logout: "退出登录",
  login_failure: "登录失败",
  search: "搜索",
  a_search: "高级搜索",
  confirm: "确定",
  close: "关闭",
  edit: "编辑",
  view: "查看",
  cancel: "取消",
  save: "保存",
  back: "返回",
  delete: "删除",
  gender: "性别",
  male: "男",
  female: "女",
  age: "年龄",
  a_unlimited: "年龄不限",
  no_data: "暂无数据",
  name: "姓名",
  vbm: "VBM",
  s_time: "提交时间",
  u_time: "更新时间",
  operate: "操作",
  operate_menu: "操作菜单",
  colon: "：",
  voi_edit: "VOI编辑",
  attention: "注",
  status: "状态",
  expand: "展开",
  collapse: "收起",
  name: "名称",
  date: "日期",
  time: "时间",
  batch: "批量",
  sign: {
    create_account: "创建账户",
    email: "邮箱地址",
    username: "用户名",
    password: "密码",
    re_enter_pass: "再次输入密码",
    invi_code: "邀请码",
    sign_tetx1: "创建账户，即视为您同意遵守迈格生命科技的",
    statement: "使用声明。",
    sign_tetx2: "创建账户",
    sign_text3: "已拥有账户？登录",
    sign_in: "登录",
    forgot: "忘记密码？",
    reset_pass: "修改密码",
    sign_text4: "邮箱地址或用户名",
    sign_text5: "发送验证码",
    sign_text6: "已拥有账户？登录",
    veri_code: "验证码",
    new_pass: "新密码",
    original_password: "原密码",
  },
  summary: {
    total_datas: "数据总量",
    total_projects: "项目总量",
    total_departments: "实验室总量",
    total_doctors: "首席研究员总量",
    total_students: "研究员总量",
    age_gender: "数据年龄性别分布",
    modality: "数据模态分布",
    application: "各分析应用使用数量",
    tag: "数据标签分布",
    male: "男",
    female: "女",
  },
  _data: {
    manage: "数据管理",
    add_data: "添加数据",
    batch_edit: "批量设置数据负责人",
    batch_examined: "批量设置检查部位",
    batch_seriestype: "批量设置序列类型",
    modified_repeatedly: "修改成功之后，将不可再次更改",
    all: "全选",
    p_name: "受试者名称",
    upload_time: "上传时间",
    tags: "标签",
    data_owner: "数据负责人",
    department: "实验室",
    edit_owner: "设置数据负责人",
    batch_download: "批量下载",
    series_type: "序列类型",
    diagnostic: "诊断说明",
    img_files: "影像文件-Dicom(文件大小不超过2G)",
    drag_upload: "将zip压缩文件拖拽至此，或",
    click_upload: "点击上传",
    click_download: "点击下载",
    other_file: "其他文件",
    select_file: "选取文件",
    only_zip: "只能上传.zip文件",
    only_onefile: "一次只能上传一个文件",
    clear_filter: "清空筛选",
    patient_info: "受试者信息",
    edit_p_info: "修改受试者信息",
    modify: "修改",
    download_list: "下载列表",
    d_nif: "下载Nifti数据",
    d_dic: "下载DICOM数据",
    d_voi: "下载标注数据",
    data_nifti: "Nifti数据",
    data_dicom: "Dicom数据",
    data_voi: "标注数据",
    data_statistic: "统计数据",
    data_result: "结果数据",
    edit_tag: "设置标签",
    add_to_pro: "添加至项目",
    project: "项目",
    series_num: "检查序列总数",
    study_series: "检查序列",
    study_date: "检查日期",
    p_num: "病人编号",
    series_desc: "序列描述",
    img_num: "图像数量",
    img_size: "图像大小",
    service: "分析服务",
    tips: "提醒",
    seg_completed: "该数据已完成全脑分割分析",
    processing: "查看结果",
    resubmit: "重新提交",
    packing: "正在打包数据，请稍候...",
    download: "下载",
    tag_name: "标签名称",
    manage_tag: "管理标签",
    add_tag: "添加标签",
    upload: "开始上传",
    view_dicom: "DICOM阅片",
    patient_det: "受试者详情",
    year: "岁",
    month: "月",
    week: "周",
    day: "日",
    batch_remove: "批量删除"

  },
  project: {
    manage: "项目管理",
    manager: "项目管理员",
    name: "项目名称",
    owner: "项目负责人",
    department: "实验室",
    p_num: "受试者数量",
    p_mem: "成员数量",
    series_num: "序列数量",
    c_time: "创建时间",
    u_time: "最近修改时间",
    clear: "清空",
    g_name: "分组名称",
    more: "更多",
    c_pro: "创建项目",
    s_pro: "共享项目",
    group: "分组",
    g_num: "分组数量",
    p_des: "项目描述",
    p_type: "项目类型",
    equipment: "设备",
    p_edit: "编辑项目",
    data: "数据",
    application: "服务列表",
    c_group: "创建分组",
    u_data: "添加受试者",
    e_group: "修改分组名称",
    p_name: "受试者名称",
    custom: "自定义",
    from: "从",
    to: "至",
    yes: "确认",
    s_date: "检查日期",
    d_unlimit: "时间不限",
    up_time: "上传时间",
    modality: "模态",
    tags: "标签",
    menu: "操作菜单",
    e_info: "修改受试者信息",
    e_tag: "管理标签",
    p_info: "项目信息",
    p_member: "项目成员",
    a_mem: "添加成员",
    d_mem: "删除成员",
    add: "添加",
    p_res: "查看结果",
    mri_ana: "MRI分析",
    pet_ana: "PET分析",
    co_ana: "群组分析",
    b_down: "批量下载",
    ch_group: "更换分组",
    ser_type: "序列类型",
    d_desc: "诊断说明",
    img_file: "影像文件",
    o_file: "其他文件",
    drag_file: "将文件拖拽至此，或点击上传",
    a_group: "创建分组",
    t_name: "标签名称",
    a_tag: "添加标签",
    v_based: "基于体素的形态学测量分析",
    series: "序列",
    detail: "项目数据详情",
    vbm_desc:
      "VBM(Voxel-based Morphometry) 是目前被广泛应用的一种形态计量学方法，主要利用统计推断来描述多个被试之间脑结构的差异，或寻找脑形状的相关信息以说明疾病的严重性。 与传统解剖结构的 ROI 分析方法不同，它可以检验大脑任何位置的差异；另一方面，它可以用于大脑解剖学的任何度量方式，恰当地选择数据和数据变换，大量的解剖特征可以通过简单无偏的方法加以分析。 VBM 方法通常被用来定量计算全脑灰质体积的改变，从而准确地反映脑组织形态学方面的变化。",
    tbss: "TBSS",
    t_based: "基于纤维束的空间统计分析",
    tbss_desc:
      "TBSS(Tract-based spatial statistics)用于分析弥散张量数据，可对不同被试者的主要白质纤维束对齐、配准，从而达到较精准的组间比较，因此对脑白质异常区域定位更准确，为定量评估脑白质病变提供可靠参数。TBSS能够在配准 FA 图像到目标模板之后，提取 FA 图的骨架——对应于主要脑白质纤维束，再将配准后的 FA 图像投射到对应骨架上，这样一来就不需再进行空间平滑，能有效地减小平滑对结果的影响。",
    view: "查看结果",
    c_task: "创建任务",
    c_vtask: "创建VBM任务",
    s_contrast: "选择对照组",
    s_patient: "选择病人组",
    e_conf: "设置参数",
    s_task: "提交任务",
    pat_num: "病人编号",
    ac_time: "扫描时间",
    keyword: "关键字",
    s_strip: "Skull Stripping 颅骨剥离",
    fsl_bet: "FSL BET 颅骨剥离（常规）",
    fsl_rn: "FSL BET (Remove Neck) 颅骨剥离（常规+去颈）",
    fsl_rne: "FSL BET (Remove Neck/Eyeball) 颅骨剥离（常规+去颈+去眼）",
    bse: "BSE 颅骨剥离（边缘检测）",
    ants: "ANTs 颅骨剥离（时间长）",
    img_reg: "Image Registration 图像配准",
    non_reg: "Non-linear Registration 非线性配准",
    lin_reg: "Linear Registration 线性配准",
    p_val: "P-Value P值阈值",
    p_test: "Permutation Test 统计分析方法",
    th_no_val: "Threshold-Free Cluster Enhancement cluster矫正法（无阈值）",
    gau_smooth: "Gaussian Smoothing Kernel 高斯滤波平滑核大小（单位mm）",
    per_test: "Number for Permutation Test 置换检验次数",
    th_val: "Threshold-Free Cluster Enhancement cluster（With P-Value）",
    th_clu:
      "Threshold for the cluster-based correction cluster矫正阈值(决定cluster大小)",
    atlas: "Atlas脑图谱",
    cer_atlas:
      "Cerebellar Atlas in MNI152 space after normalization with FLIRT",
    har_atlas: "Harvard-Oxford Atlas (Cortical and Subcortical) ",
    jhu_icbm: "JHU ICBM-DTI-81 White-Matter Labels",
    jhu_white: "JHU White-Matter Tractography Atlas",
    juelich: "Juelich Histological Atlas",
    mni_atlas: "MNI Structural Atlas",
    s_vtask: "提交VBM任务",
    c_ttsak: "创建TBSS任务",
    fa_temp: "FA Template FA脑模板",
    fmr_temp: "FMRIB58_FA Template标准FA脑模板",
    faske_temp: "FA Skeleton Map Template FA纤维束骨架图模板",
    meanfa_temp: "Mean FA Skeleton Map Template自适应脑骨架",
    fmrfa_temp: "FMRIB58_FA Skeleton Map Template标准FA脑骨架",
    fa_thre: "FA Skeleton Threshlod FA纤维束骨架阈值",
    per_num: "Permutation Test Number 置换检验次数",
    s_stask: "提交TBSS任务",
    mri_analy: "MRI分析",
    all: "全部",
    analy_name: "分析名称",
    status: "任务状态",
    c_report: "创建报告",
    view_report: "查看报告",
    down_data: "下载数据",
    coh_analy: "群组分析",
    download: "下载",
    con_param: "配置参数",
    vo_img: "查看原图",
    vs_img: "颅骨剥离图",
    vf_img: "FA图",
    vq_img: "QC图",
    text_selectData: "请先选择数据",
    completion_ratio: "已完成/已提交",
    text_statistic: "下载统计数据",
    text_result: "下载分析结果",
    text_batchdown_tips: "每次最多下载10个结果数据。",
    finish: "完成",
    select_data: "选择数据",
    msg_error: "错误",
    batch_analyse: "批量分析",
    batch_operations: "批量操作"
  },
  analyse: {
    t1_analyse: "全脑分割",
    pet_analyse: "PET分析",
    owner: "项目负责人",
    type: "类型",
    data_owner: "数据负责人",
    analyse_name: "分析名称",
    status: "任务状态",
    result: "查看结果",
    resubmit: "重新提交",
    t1_viewer: "在线阅片",
    pet_viewer: "PET阅片",
    task_name: "任务名称",
    body_part: "检查部位",
    fuzzy_search: "受试者名称/年龄/性别/任务名称",
    series_time: "检查时间",
    download_statistic: "下载统计数据",
    download_result: "下载结果数据",
    download_label: "下载标注数据",
    dti_viewer: "DTI阅片",
    flair_viewer: "FLAIR阅片",
    viewer: "阅片",
    viewer_reporter: "阅片和报告",
    label: "标注"
  },
  tags: {
    add_tag: "添加标签",
    edit_tag: "编辑标签",
    tag_name: "标签名称",
    tag_desc: "标签描述",
    tag_message_require: "请输入标签名",
  },
  rule: {
    rules: "规则",
    series: "序列",
    add_series: "添加序列",
    add_key: "添加序列描述",
    more: "更多",
    fold: "收起",
    modality_type: "模态类型",
    modality_desc: "模态描述",
    series_type: "序列类型",
    key_word: "序列描述",
    edit_key: "编辑序列描述",
    edit_series: "编辑序列",
    edit: "操作",
    creator: "创建人"
  },
  student: {
    management: "研究员管理",
    add_student: "添加研究员 ",
    edit_student: "修改研究员 ",
    student: "研究员",
    doctor: "首席研究员 ",
    department: "实验室",
    organization: "机构",
    project_num: "项目数量",
    patient_num: "受试者数量",
    update_time: "最近修改时间",
    status: "账号状态",
    open: "启用",
    close: "禁用",
    right: "权限操作",
    edit_right: "设置权限",
    edit: "操作",
    modify: "修改",
    email: "电子邮箱",
    init_password: "初始密码",
    username: "用户名",
    tel_number: "电话",
  },
  department: {
    add: "添加实验室",
    edit_department: "修改实验室",
    manager: "实验室管理员",
    doctor_count: "首席研究员账户数量",
    student_count: "研究员账户数量",
    project_count: "项目数量",
    patient_count: "受试者数量",
    department: "实验室",
    update_time: "最近修改时间",
    status: "账号状态",
    open: "启用",
    close: "禁用",
    right: "权限操作",
    edit_right: "设置权限",
    edit: "操作",
    modify: "修改",
    email: "电子邮箱",
    init_password: "初始密码",
    username: "用户名",
    tel_number: "电话",
    title: "职称",
  },
  doctor: {
    add: "添加首席研究员",
    edit_doctor: "修改首席研究员",
    student_count: "研究员账户数量",
    project_count: "项目数量",
    patient_count: "受试者数量",
    department: "实验室",
    update_time: "最近修改时间",
    status: "账号状态",
    open: "启用",
    close: "禁用",
    right: "权限操作",
    edit_right: "设置权限",
    edit: "操作",
    modify: "修改",
    email: "电子邮箱",
    init_password: "初始密码",
    username: "用户名",
    tel_number: "电话",
  },
  authority: {
    right_det: "权限内容",
    basic_info: "基本信息",
    student: "研究员",
    doctor: "首席研究员",
    department: "实验室",
    service: "服务应用",
    analysis: "分析应用",
    brain_t118: "T1-118脑区",
    brain_t238: "T1-238脑区",
    total: "总计：",
    remaining: "剩余：",
    Used: "已使用：",
    undistributed: "未分配",
    project_application: "项目应用",
    nifti_download: "Nifti数据下载",
    dicom_download: "Dicom数据下载",
    clear: "清空"
  },
  person: {
    personal_info: "个人信息",
    email: "电子邮箱",
    username: "用户名",
    name: "姓名",
    department: "实验室",
    org: "单位",
    title: "职称",
    telephone: "电话",
  },
  service: {
    core: "内核",
    core_cpu: "CPU",
    core_gpu: "GPU",
    service: "服务应用",
    add: "添加服务",
    create: "创建应用",
    base_info: "基本信息",
    name: "应用名称",
    profile: "应用简介",
    desc: "应用描述",
    change_icon: "点击更换应用图标",
    data_type: "数据类型",
    modality: "影像数据模态-序列类型",
    series: "影像数据序列类型",
    interface_info: "接口信息",
    nifti_upload: "上传Nifti数据接口",
    inter_progress: "获取分析进度接口",
    view_results: "查看结果接口",
    edit: "编辑应用",
    set_param: "参数设置",
    app_path: "应用路径",
    custom_path: "自定义文件挂载路径",
    call_path: "应用调用路径",
    system: "系统参数",
    custom_param: "自定义参数",
    add_param: "添加参数",
    param_name: "参数名称",
    param_type: "参数类型",
    computer_resource: "电脑资源",
    cpu: "CPU（核）",
    memory: "内存（GB）",
    add_text_param: "添加文本参数",
    add_single_param: "添加单选参数",
    add_mult_param: "添加多选参数",
    edit_text_param: "编辑文本参数",
    edit_single_param: "编辑单选参数",
    edit_mult_param: "编辑多选参数",
    image_type: "影像数据类型",
    add_input: "添加输入",
    data_format: "数据格式",
    download_text: "请填写结果文件或统计文件相对于输出根目录的路径",
    app_icon: "应用图标",
    input: "输入数据",
    result_data: "结果数据管理",
    result_title: "结果数据下载",
    result_view: "结果数据查看",
    download_result: "结果数据下载路径",
    download_statistical: "统计数据下载路径",
    create_task: "提交分析任务",
    set_param: "设置参数",
    create_succ: "提交成功",
    task_name: "任务名称",
    next: "下一步",
    submit_task: "提交任务",
    previous: "上一步",
    last_step: "提交成功，您的分析任务已提交，请进入影像分析页面查看任务进程。",
    notice1: "请输入Docker Image Name",
    notice2: "请输入应用调用路径",
    notice3: "请完善当前Input的所有相关信息",
    notice4: "请输入下载路径",
    notice5: "请先选择“结果数据下载路径”",
    notice6: "请先选择“统计数据下载路径”",
    notice7: "每一个参数请设置相应的值",
    notice8: "请选择影像数据类型",
    notice9: "请选择数据",
    notice10: "Docker Image Name输入格式错误",
    notice11: "自定义文件挂载路径输入格式错误",
    notice12: "请填写VOI编辑相关信息",
    notice13: "年龄必须为正数",
    notice14: "请选择性别",
    enable_text: "服务应用（可用）",
    disable_text: "服务应用（不可用）",
    user_visible: "用户可见",
    user_invisible: "用户不可见",
    generic: "通用",
    none: "无"
  },
  log: {
    account_type: "账户类型",
    email: "邮箱",
    operator: "操作人",
    op_name: "操作名称",
    op_result: "操作结果",
    op_time: "操作时间",
  },
  notice: {
    p_confirm: "确定要删除？",
    modify_confirm: "确定要修改？",
    add_confirm: "确定要添加？",
    tips: "提醒",
    download: "下载",
    enable_ok: "启用成功",
    enable_failed: "启用失败",
    disable_ok: "禁用成功",
    disable_failed: "禁用失败",
    on_delete: "正在删除...",
    del_ok: "删除成功",
    del_failed: "删除失败",
    on_save: "正在保存",
    save_ok: "保存成功",
    save_failed: "保存失败",
    on_clear: "正在清空...",
    clear_ok: "清空成功",
    clear_failed: "清空失败",
    select_tip: "请选择批量下载数据",
    on_batch_download: "正在批量下载...",
    batch_download_ok: "批量下载成功",
    batch_download_failed: "批量下载失败",
    on_download: "正在下载...",
    download_ok: "下载成功",
    download_failed: "下载失败",
    on_submit: "正在提交任务...",
    submit_ok: "提交任务成功",
    submit_failed: "提交任务失败",
    on_resubmit: "正在重新提交任务...",
    resubmit_ok: "重新提交成功",
    resubmit_failed: "重新提交失败",
    add_tag_ok: "添加标签成功",
    add_tag_failed: "添加标签失败",
    add_ok: "添加成功",
    add_failed: "添加失败",
    packing: "正在打包数据",
    pack_ok: "打包数据成功",
    pack_failed: "打包数据失败",
    on_export: "正在批量导出日志...",
    export_ok: "批量导出日志成功",
    export_failed: "批量导出日志失败",
    select_data: "请选择数据",
    select_group: "请选择分组",
    select_member: "请选择成员",
    no_upload_data: "无数据上传",
    p_name_required: "受试者名称为空",
    age_required: "年龄为空",
    tag_name_required: "标签名称为空",
    select_tag: "请选择标签",
    select_data_owner: "请选择数据负责人",
    select_data_upload: "请选择上传文件"
  },
  validate: {
    department: "实验室为必填项",
    department_manager: "实验室管理员为必填项",
    email: "电子邮箱为必填项",
    init_password: "初始密码为必填项",
    password: "密码为必填项",
    analysis: "分析数量为必填项",
    doctor: "首席研究员为必填项",
    student: "研究员为必填项",
    data_owner: "数据负责人为必选项",
    tag_name: "标签名称为必填项",
    project_name: "项目名称为必填项",
    project_owner: "项目负责人为必填项",
    patient_name: "受试者名称为必填项",
    age: "年龄为必填项",
    project_member: "项目成员为必选项",
    group: "分组为必选项",
    tag: "标签为必选项",
    control_group: "对照组为必选项",
    parameters: "请设置参数",
    number: "请输入数字",
    patient_group: "病人组为必选项",
    task_name: "任务名称为必填项",
    organization: "机构名称为必填项",
    username: "用户名为必填项",
    name: "姓名为必填项",
    login_username: "用户名不能为空",
    login_password: "密码不能为空",
  },
  task_status: {
    unsubmit: "未提交",
    created: "已提交",
    wait: "等待处理",
    processing: "正在处理",
    completed: "处理完成",
    failed: "处理失败",
    pause: "暂停",
    timeout: "超时",
    license_expired: "软件授权过期",
    license_not_found: "软件未经授权",
    license_error: "软件授权失败",
    invalid: "失效",
  },
  package_status: {
    completed: "处理完成",
    processing: "正在处理",
    failed: "处理失败",
    failure_reason: "失败原因",
    no_dicom: "未发现DICOM文件", //error_status = 2
    failed_niix: "无法解压或转Nifti失败", //error_status = 1
  },
  copyright: {
    about: "关于",
    company: "迈格生命科技(深圳)有限公司",
    version: "版本",
    date: "日期",
    terms: "许可条款",
    term_1: "请注意，使用该软件时，需遵守购买该软件附带的许可协议中的条款和条件。",
    term_2: "如果您未从迈格或其授权分销商购买有效许可证，则不得使用该软件。"
  },
  ...zhLocale,
};
