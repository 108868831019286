<template>
  <div>
    <SystemHead />
    <div class="system-container">
      <Siderbar />
      <div class="main-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>


<script>
import Siderbar from "./siderBar";
import SystemHead from "./systemHead";
export default {
  components: {
    Siderbar,
    SystemHead,
  },
};
</script>

<style lang="less">
.system-container {
  position: relative;
  padding-left: 242px;
  padding-right: 20px;
}
.main-container {
  width: 100%;
  position: relative;
  padding-top: 52px;
  padding-bottom: 70px;
}
</style>