<template>
  <div class="sidebar">
    <div class="sidebar-sticky shadow">
      <div class="nav flex-column">
        <router-link
          v-for="item in menuData"
          :key="item.path"
          :to="item.path"
          class="nav-item nav-link clearfix"
        >
          <i class="fl" :class="item.meta.icon"></i>
          <span class="fl">{{ $t(item.meta.lang_key) }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    this.selectedKeysMap = {};
    const menuData = this.getMenuData(this.$router.options.routes);
    return {
      menuData,
      selectedKeys: this.selectedKeysMap[this.$route.path],
    };
  },
  methods: {
    checkDepartmentLimits(department_limits) {
      let user_rights = localStorage.getItem("user_rights")
        ? JSON.parse(localStorage.getItem("user_rights"))
        : {};

      const r =
        typeof user_rights.analysis != "undefined" &&
        user_rights.analysis == department_limits.analysis;

      return r;
    },
    checkAuthority(authority) {
      //根据用户的角色判断是否显示相关菜单
      const user = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {};
      return authority.includes(user.role);
    },
    getMenuData(routes = [], parentKeys = [], selectedKey) {
      const menuData = [];
      for (let item of routes) {
        if (
          item.meta &&
          item.meta.authority &&
          !this.checkAuthority(item.meta.authority)
        ) {
          continue;
        }

        if (
          item.meta &&
          item.meta.department_limits &&
          this.checkDepartmentLimits(item.meta.department_limits)
        ) {
          continue;
        }

        if (item.name && !item.hideInMenu) {
          this.selectedKeysMap[item.path] = selectedKey || item.path;
          const newItem = { ...item };
          delete newItem.children;
          menuData.push(newItem);
        } else if (
          !item.hideInMenu &&
          !item.hideChildrenMenu &&
          item.children
        ) {
          menuData.push(
            ...this.getMenuData(item.children, [...parentKeys, item.path])
          );
        }
      }
      return menuData;
    },
  },
  watch: {
    "$route.path": function (val) {
      this.selectedKeys = this.selectedKeysMap[val];
    },
  },
};
</script>

<style lang="less">
.sidebar {
  width: 222px;
  .nav-item {
    text-align: left;
  }
  .router-link-active {
    color: rgb(245, 244, 243);
  }
}
</style>
