<template>
  <div>
    <nav
      class="navbar navbar-dark bg-dark fixed-top flex-md-nowrap p-0 shadow consoleheader"
    >
      <router-link
        class="navbar-brand mr-0 text-center"
        to="/"
        :style="{ backgroundColor: logoBgColor }"
      >
        <span>
          <img
            :src="VUE_APP_LOGO == 'guocm' ? logo_guocm : logo"
            class="logo"
            alt=""
          />
        </span>
      </router-link>
      <nav class="nav nav-masthead justify-content-center">
        <div
          class="top-nav-link text-white-50 lang-switch"
          @click.stop="showLang = !showLang"
        >
          <img :src="imgUrl" :style="imgStyle" alt="" />
          <span class="ml5 lang-text">{{ lang_text }}</span>
          <span class="trangle" :class="showLang ? 'active' : ''"></span>
          <div class="lang-select" v-show="showLang">
            <p class="lang-item" @click="selectLang('zh')">
              <img :src="Flag_cn" :style="imgStyle" alt="" />
              <span class="ml5">简体中文</span>
            </p>
            <p class="lang-item" @click="selectLang('en')">
              <img :src="Flag_us" :style="imgStyle" alt="" />
              <span class="ml5">English (United States)</span>
            </p>
          </div>
        </div>
        <router-link
          to="/personal-info"
          class="top-nav-link text-white-50 clearfix"
          v-if="pageType !== 'sign'"
        >
          <i class="iconfont iconyonghu fl"></i>
          <span class="ml5 fl">{{ username }}</span>
        </router-link>
        <a class="top-nav-link text-white-50" @click="copyrightVisible = true">
          <i class="el-icon-info"></i> {{ $t("copyright.about") }}
        </a>
        <a
          v-if="pageType !== 'sign'"
          class="top-nav-link text-white-50 clearfix"
          @click="logout"
        >
          <i class="iconfont icontuichu fl"></i>
          <span class="ml5 fl">{{ $t("logout") }}</span>
        </a>
      </nav>
    </nav>

    <el-dialog
      :title="$t('copyright.about')"
      :visible.sync="copyrightVisible"
      width="30%"
    >
      <dl class="lh-sm">
        <dt class="mb-2">Copyright &copy; {{ $t("copyright.company") }}</dt>
        <dd v-if="backendConfig.copyright && backendConfig.copyright.version">
          {{ $t("copyright.version") }}：{{ backendConfig.copyright.version }}
        </dd>
        <dd v-if="backendConfig.copyright && backendConfig.copyright.date">
          {{ $t("copyright.date") }}：{{ backendConfig.copyright.date }}
        </dd>
        <dd
          v-if="backendConfig.copyright && backendConfig.copyright.expiry_date"
        >
          {{ $t("copyright.expiry_date") }}：{{
            backendConfig.copyright.expiry_date
          }}
        </dd>
      </dl>

      <dl>
        <dt class="mb-2 mt-3">
          <small>{{ $t("copyright.terms") }}</small>
        </dt>
        <dd>
          <ul class="m-0 p-0 ps-3">
            <li>
              <small>{{ $t("copyright.term_1") }}</small>
            </li>
            <li>
              <small>{{ $t("copyright.term_2") }}</small>
            </li>
          </ul>
        </dd>
      </dl>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyrightVisible = false">{{
          $t("close")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import logo from "@/assets/logo.png";
import logo_guocm from "@/assets/guocm.png";
import logo_sanyuan from "@/assets/sanyuan.png";
import flag_cn from "../assets/flag_cn.jpg";
import flag_us from "../assets/flag_us.jpg";
import { eventHub } from "@/eventHub.js";

export default {
  props: ["pageType"],
  data() {
    return {
      imgStyle: {
        width: "23px",
        height: "12px",
      },
      logo: logo,
      logo_guocm: logo_guocm,
      Flag_cn: flag_cn,
      Flag_us: flag_us,
      trangleClass: "active",
      showLang: false,
      selected_lang: this.$store.state.language || "zh",
      copyrightVisible: false,
      VUE_APP_LOGO: process.env.VUE_APP_LOGO,
      logoBgColor: "#212529",
    };
  },
  computed: {
    ...mapState({
      backendConfig: (state) => state.backendConfig,
    }),
    lang_text: function () {
      return this.$store.state.language === "en"
        ? "English (United States)"
        : "简体中文";
    },
    imgUrl: function () {
      return this.$store.state.language === "en" ? flag_us : flag_cn;
    },
    username: function () {
      return this.$store.state.username || "";
    },
  },
  created() {
    let env_logo_name = process.env.VUE_APP_LOGO;
    console.log("env_logo_name", env_logo_name);
    console.log("VUE_APP_LOGO", this.VUE_APP_LOGO);
    switch (env_logo_name) {
      case "guocm":
        this.logo = logo_guocm;
        this.logoBgColor = "#212529";
        break;

      case "sanyuan":
        this.logo = logo_sanyuan;
        this.logoBgColor = "#fff";
        break;

      default:
        this.logo = logo;
        this.logoBgColor = "#212529";
        break;
    }
  },
  methods: {
    selectLang(lang) {
      this.$store.commit("selectLang", lang);
      //sessionStorage.setItem("lang", lang);
      localStorage.setItem("lang", lang);
      this.$i18n.locale = lang;
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");

      eventHub.$emit("closeProcessList");
      this.$router.push("/login");
    },
    hideLang() {
      this.showLang = false;
    },
  },
  mounted() {
    document.body.addEventListener("click", this.hideLang, false);
  },
  beforeDestroy() {
    document.body.removeEventListener("click", this.hideLang);
  },
};
</script>

<style lang="less">
.consoleheader .navbar-brand {
  width: 223px;
  background-color: #fff;
}
.logo {
  height: 36px;
}
.top-nav-link {
  display: block;
  padding: 6px 10px;
  height: 36px;
  line-height: 24px;
  .iconfont {
    font-size: 18px;
  }
}
.lang-switch {
  position: relative;
  cursor: pointer;
  .lang-text {
    margin-right: 10px;
  }
  .trangle {
    display: block;
    position: absolute;
    top: 16px;
    right: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px;
    border-color: #6b7174 transparent transparent transparent;
    &.active {
      transform: rotate(180deg);
      transform-origin: 50% 25%;
    }
  }
  .lang-select {
    position: absolute;
    top: 30px;
    right: 10px;
    border-radius: 2px;
    width: 175px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
    color: #212528;
    .lang-item {
      margin-bottom: 0;
      padding: 6px 10px;
      cursor: pointer;
      &:hover {
        background: #f8f9fa;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
</style>
