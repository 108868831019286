import enLocale from "element-ui/lib/locale/lang/en";

export default {
  nav: {
    summary: "Summary",
    data_manage: "Data Management",
    project_manage: "Project Management",
    MRI_analysis: "Image Analysis",
    PET_analysis: "PET Analysis",
    cohort: "Cohort Analysis",
    rule: "Rule Management",
    series_manage: "Series Management",
    tag_manage: "Tag Management",
    tool_download: "Download Tool",
    service: "Applications",
    user_info: "Personal Information",
    reset_password: "Modify Password",
    department: "Laboratory Management",
    doctor: "Chief Researcher Management",
    student: "Researcher Management",
    log: "Operation log",
  },
  logout: "logout",
  login_failure: "Login failure",
  search: "Search",
  a_search: "Advanced Search",
  confirm: "Confirm",
  close: "Close",
  edit: "Edit",
  view: "View",
  cancel: "Cancel",
  save: "Save",
  back: "Back",
  delete: "Delete",
  gender: "Gender",
  male: "Male",
  female: "Female",
  age: "Age",
  a_unlimited: "Age Unlimited",
  no_data: "No data",
  name: "Name",
  vbm: "VBM",
  s_time: "Submit Time",
  u_time: "Update Time",
  operate: "More",
  operate_menu: "Menu",
  colon: ":",
  voi_edit: "VOI editor",
  attention: "Attention",
  status: "Status",
  expand: "Expand",
  collapse: "Collapse",
  name: "Name",
  date: "Date",
  time: "Time",
  batch: "Batch",
  sign: {
    create_account: "Create account",
    email: "Email",
    username: "Username",
    password: "Password",
    re_enter_pass: "Re-enter password",
    invi_code: "Invitation Code",
    sign_tetx1: "By creating an account, you agree to Mindsgo's",
    statement: "Statement of Use.",
    sign_tetx2: "Create your account",
    sign_text3: "Already have an account? Sign in",
    sign_in: "Sign in",
    forgot: "Forgot Password?",
    reset_pass: "Reset Password",
    sign_text4: "Email or Username",
    sign_text5: "Send Verification Code",
    sign_text6: "Already have an account? Sign in",
    veri_code: "Verification Code",
    new_pass: "New Password",
    original_password: "Original password",
  },
  summary: {
    total_datas: "Total Datas",
    total_projects: "Total Projects",
    total_departments: "Total Laboratories",
    total_doctors: "Total Chief Researchers",
    total_students: "Total Researchers",
    age_gender: "Age and Gender Distribution",
    modality: "Modality Distribution",
    application: "Application Number",
    tag: "Tag Distribution",
    male: "M",
    female: "F",
  },
  _data: {
    manage: "Data Management",
    add_data: "Add Data",
    batch_edit: "Batch Edit Data Owner",
    batch_examined: "Batch Edit Body Part Examined ",
    batch_seriestype: "Batch Edit Series Type",
    modified_repeatedly: "Can not be modified repeatedly",
    all: "All",
    p_name: "Patient Name",
    upload_time: "Upload Time",
    tags: "Tags",
    data_owner: "Data Owner",
    department: "Laboratory",
    edit_owner: "Edit Data Owner",
    batch_download: "Batch Download Data",
    series_type: "Series Type",
    diagnostic: "Diagnostic Description",
    img_files: "Image Files - Dicom (File Limit 2G)",
    drag_upload: "Drag zip file here, or ",
    click_upload: "Upload",
    click_download: "Download",
    other_file: "Other Files",
    select_file: "Select file",
    only_zip: "Must be .zip files.",
    only_onefile: "Only one file can be uploaded at once",
    clear_filter: "Clear",
    patient_info: "Patient Information",
    edit_p_info: "Edit Patient Information",
    modify: "Edit",
    download_list: "Download List",
    d_nif: "Download Nifiti Data",
    d_dic: "Download DICOM Data",
    d_voi: "Download Label Data",
    data_nifti: "Nifti Data",
    data_dicom: "Dicom Data",
    data_voi: "Label Data",
    data_statistic: "Statistic",
    data_result: "Result Data",
    edit_tag: "Edit Tag",
    add_to_pro: "Add to Project",
    project: "Project",
    series_num: "Series Number",
    study_series: "Study Series",
    study_date: "Study Date",
    p_num: "Patient Number",
    series_desc: "Series Description",
    img_num: "Image Number",
    img_size: "Image Size",
    service: "Application",
    tips: "Tips",
    seg_completed: "Brain segmentation analysis completed.",
    processing: "Processing Result",
    resubmit: "Resubmit",
    packing: "Packing，please wait...",
    download: "Download",
    tag_name: "Tag Name",
    manage_tag: "Edit Tag",
    add_tag: "Add Tag",
    upload: "Upload",
    view_dicom: "DICOM Viewer",
    patient_det: "Patient Details",
    year: "Years",
    month: "Months",
    week: "Weeks",
    day: "Days",
    batch_remove: "Batch Delete"
  },
  project: {
    manage: "Project Management",
    manager: "Project Manager",
    name: "Project Name",
    owner: "Owner",
    department: "Laboratory",
    p_num: "Patient's Number",
    p_mem: "Project Member",
    series_num: "Series Number",
    c_time: "Create Time",
    u_time: "Update Time",
    clear: "Clear",
    g_name: "Group Name",
    more: "More",
    c_pro: "Create Project",
    s_pro: "Shared Project",
    group: "Group",
    g_num: "Group Number",
    p_des: "Project Description",
    p_type: "Project Type",
    equipment: "Equipment",
    p_edit: "Edit Project",
    data: "Data",
    application: "Applications",
    c_group: "Create Group",
    u_data: "Upload Data",
    e_group: "Edit Group",
    p_name: "Patient Name",
    custom: "Custom",
    from: "From",
    to: "To",
    yes: "Yes",
    s_date: "Study Date",
    d_unlimit: "Date Unlimited",
    up_time: "Upload Time",
    modality: "Modality",
    tags: "Tags",
    menu: "Menu",
    e_info: "Edit Patient Information",
    e_tag: "Edit Tag",
    p_info: "Project Information",
    p_member: "Project Member",
    a_mem: "Add Member",
    d_mem: "Delete",
    add: "Add",
    p_res: "Processing Result",
    mri_ana: "MRI Analysis",
    pet_ana: "PET Analysis",
    co_ana: "Cohort Analysis",
    b_down: "Batch Download Data",
    ch_group: "Change Group",
    ser_type: "Series Type",
    d_desc: "Diagnostic Description",
    img_file: "Image File",
    o_file: "Other File",
    drag_file: "Drag files here, or Upload",
    a_group: "Add Group",
    t_name: "Tag Name",
    a_tag: "Add Tag",
    v_based: "Voxel-based Morphometry",
    series: "Series",
    detail: "Project Details",
    vbm_desc:
      "Voxel-based morphometry is a computational approach to neuroanatomy that measures differences in local concentrations of brain tissue, through a voxel-wise comparison of multiple brain images. The value of VBM is that it allows for comprehensive measurement of differences, not just in specific structures, but throughout the entire brain. VBM registers every brain to a template, which gets rid of most of the large differences in brain anatomy among people. Then the brain images are smoothed so that each voxel represents the average of itself and its neighbors. Finally, the image volume is compared across brains at every voxel.",
    tbss: "TBSS",
    t_based: "Tract-based spatial statistics",
    tbss_desc:
      "TBSS aims to improve the sensitivity, objectivity and interpretability of analysis of multi-subject diffusion imaging studies. TBSS (Smith et al., 2006) was used for voxelwise analysis of whole brain WM DTI measures. In short, the group mean FA image was created and thinned to generate a mean FA skeleton, which is thought to represent the centers of all white matter tracts common to the group. All FA images were co-registered to each other using nonlinear registration (Rueckert et al., 1999). The average amount of warping that was needed to align all other images to each FA image was estimated. The one that had the smallest amount of average warping was selected as a “target” image which was thought to be the most “typical” one of all study participants. All FA images were then nonlinearly transformed into the space of the target FA image. For each individual, local FA maxima were found along the perpendicular direction to the mean FA image and then projected onto the group template skeleton (Smith et al., 2006). The final image was normalized to MNI space using linear transformations,. Voxelwise analyses across the group of study participants were carried out for all voxels.",
    view: "View",
    c_task: "Create Task",
    c_vtask: "Create VBM Task",
    s_contrast: "Select Contrast",
    s_patient: "Select Patient",
    e_conf: "Edit Configuration Parameters",
    s_task: "Submit Task",
    pat_num: "Patient Number",
    ac_time: "Acquisition Time",
    keyword: "Keyword",
    s_strip: "Skull Stripping",
    fsl_bet: "FSL BET",
    fsl_rn: "FSL BET (Remove Neck)",
    fsl_rne: "FSL BET (Remove Neck/Eyeball)",
    bse: "BSE",
    ants: "ANTs",
    img_reg: "Image Registration",
    non_reg: "Non-linear Registration",
    lin_reg: "Linear Registration",
    p_val: "P-Value",
    p_test: "Permutation Test",
    th_no_val: "Threshold-Free Cluster Enhancement cluster（Without P-Value）",
    gau_smooth: "Gaussian Smoothing Kernel （mm）",
    per_test: "Number for Permutation Test",
    th_val: "Threshold-Free Cluster Enhancement cluster（With P-Value）",
    th_clu: "Threshold for the cluster-based correction cluster",
    atlas: "Atlas",
    cer_atlas:
      "Cerebellar Atlas in MNI152 space after normalization with FLIRT",
    har_atlas: "Harvard-Oxford Atlas (Cortical and Subcortical) ",
    jhu_icbm: "JHU ICBM-DTI-81 White-Matter Labels",
    jhu_white: "JHU White-Matter Tractography Atlas",
    juelich: "Juelich Histological Atlas",
    mni_atlas: "MNI Structural Atlas",
    s_vtask: "Submit VBM Task",
    c_ttsak: "Create TBSS Task",
    fa_temp: "FA Template FA",
    fmr_temp: "FMRIB58_FA Template",
    faske_temp: "FA Skeleton Map Template",
    meanfa_temp: "Mean FA Skeleton Map Template",
    fmrfa_temp: "FMRIB58_FA Skeleton Map Template",
    fa_thre: "FA Skeleton Threshlod",
    per_num: "Permutation Test Number",
    s_stask: "Submit TBSS Task",
    mri_analy: "MRI Analysis",
    all: "All",
    analy_name: "Analysis Name",
    status: "Status",
    c_report: "Create Report",
    view_report: "View Report",
    down_data: "Download Data",
    coh_analy: "Cohort Analysis",
    download: "Download",
    con_param: "Configuration Parameters",
    vo_img: "View Original Image",
    vs_img: "View Skull Stripping Image",
    vf_img: "View FA Image",
    vq_img: "View QC Image",
    text_selectData: "Please select the Data",
    completion_ratio: "Processed/Submitted",
    text_statistic: "Download Statistic",
    text_result: "Download Processed Data",
    text_batchdown_tips: "At most 10 data for each time",
    finish: "Finish",
    select_data: "Select Data",
    msg_error: "Error",
    batch_analyse: "Submit Task",
    batch_operations: "Batch Operations"
  },
  analyse: {
    t1_analyse: "T1 Segmentation",
    pet_analyse: "PET Analysis",
    owner: "Owner",
    type: "Type",
    data_owner: "Data Owner",
    analyse_name: "Analysis Name",
    status: "Status",
    result: "View Results",
    resubmit: "Resubmit",
    t1_viewer: "T1 Viewer",
    pet_viewer: "PET Viewer",
    task_name: "Task name",
    body_part: "Body Part Examined",
    fuzzy_search: "Patient Name/Gender/Age/Task Name",
    series_time: "Series Time",
    download_statistic: "Download Statistics",
    download_result: "Download Processed Data",
    download_label: "Download Label Data",
    dti_viewer: "DTI Viewer",
    flair_viewer: "FLAIR Viewer",
    viewer: "Viewer",
    viewer_reporter: "Viewer & Report",
    label: "Label"
  },
  tags: {
    add_tag: "Add Tag",
    edit_tag: "Edit Tag",
    tag_name: "Tag Name",
    tag_desc: "Tag Description",
    tag_message_require: "Please Input Tag Name",
  },
  rule: {
    rules: "Rules",
    series: "Series",
    add_series: "Add Series",
    add_key: "Add Key Word",
    more: "More",
    fold: "Fold",
    modality_type: "Modality Type",
    modality_desc: "Modality Description",
    series_type: "Series Type",
    key_word: "Key Word",
    edit_key: "Edit Key Word",
    edit_series: "Edit Series",
    edit: "Edit",
    creator: "Founder"
  },
  student: {
    management: "Researcher Management",
    add_student: "Add Researcher",
    edit_student: "Edit Researcher",
    student: "Researcher",
    doctor: "Chief Researcher",
    department: "Laboratory",
    organization: "Organization",
    project_num: "Project Number",
    patient_num: "Patient Number",
    update_time: "Update Time",
    status: "Status",
    open: "Active",
    close: "Disable",
    right: "Right",
    edit_right: "Edit Right",
    edit: "Edit",
    modify: "Edit",
    email: "Email",
    init_password: "Initial Password",
    username: "Username",
    tel_number: "Telephone Number",
  },
  department: {
    add: "Add Laboratory",
    edit_department: "Edit Laboratory",
    manager: "Laboratory Manager",
    doctor_count: "Total Chief Researchers",
    student_count: "Total Researchers",
    project_count: "Total Projects",
    patient_count: "Total Patients",
    department: "Laboratory",
    update_time: "Update Time",
    status: "Status",
    open: "Open",
    close: "Close",
    right: "Right",
    edit_right: "Edit Right",
    edit: "Edit",
    modify: "Edit",
    email: "Email",
    init_password: "Initial Password",
    username: "Username",
    tel_number: "Telephone Number",
    title: "Title",
  },
  doctor: {
    add: "Add Chief Researcher",
    edit_doctor: "Edit Chief Researcher",
    student_count: "Total Researchers",
    project_count: "Total Projects",
    patient_count: "Total Patients",
    department: "Laboratory",
    update_time: "Update Time",
    status: "Status",
    open: "Open",
    close: "Close",
    right: "Right",
    edit_right: "Edit Right",
    edit: "Edit",
    modify: "Edit",
    email: "Email",
    init_password: "Initial Password",
    username: "Username",
    tel_number: "Telephone Number",
  },
  authority: {
    right_det: "Right Details",
    basic_info: "Basic Information",
    student: "Researchers",
    doctor: "Chief Researcher",
    department: "Laboratory",
    service: "Service",
    analysis: "Application",
    brain_t118: "T1-118",
    brain_t238: "T1-238",
    total: "Total:",
    remaining: "Remaining:",
    Used: "Used:",
    undistributed: "Undistributed",
    project_application: "Project Application",
    nifti_download: "Download Nifti Data",
    dicom_download: "Download Dicom Data",
    clear: "Clear",
  },
  person: {
    personal_info: "Personal Information",
    email: "Email",
    username: "Username",
    name: "Name",
    department: "Laboratory",
    org: "Organization",
    title: "Title",
    telephone: "Telephone Number",
  },
  service: {
    core: "Core Type",
    core_cpu: "CPU",
    core_gpu: "GPU",
    service: "Service",
    add: "Add Application",
    create: "Creat Application",
    base_info: "Basic Information",
    name: "Application Name",
    profile: "Application Profile",
    desc: "Application Description",
    change_icon: "Click to change application icon.",
    data_type: "Data Type",
    modality: "Modality Type",
    series: "Series Type",
    interface_info: "Interface Information",
    nifti_upload: "Upload Nifti Data Interface",
    inter_progress: "Analysis Progress Interface",
    view_results: "View Results Interface",
    edit: "Edit Application",
    set_param: "Set Parameter",
    app_path: "Application Path",
    custom_path: "Custom File Mounting Path",
    call_path: "Application Call Path",
    system: "System",
    custom_param: "Custom Parameter",
    add_param: "Add Parameter",
    param_name: "Parameter Name",
    param_type: "Parameter Type",
    computer_resource: "Computer Resource",
    cpu: "CPU (core)",
    memory: "Memory (GB)",
    add_text_param: "Add Text Parameter",
    add_single_param: "Add Single-option Parameter",
    add_mult_param: "Add Mult-select Parameter",
    edit_text_param: "Edit Text Parameter",
    edit_single_param: "Edit Single-option Parameter",
    edit_mult_param: "Edit Mult-select Parameter",
    image_type: "Image Data Type",
    add_input: "Add Input",
    data_format: "Data format",
    download_text:
      "Please fill in the path of the result file or statistical file relative to the output root directory.",
    app_icon: "Application Icon",
    input: "Input",
    result_data: "Results Data Management",
    result_title: "Result Data Download",
    result_view: "Result Data View",
    download_result: "Result Data Download Path",
    download_statistical: "Statistical Data Download Path",
    create_task: "Create Analysis Task",
    set_param: "Set Parameter",
    create_succ: "Created successfully",
    task_name: "Task Name",
    next: "Next",
    submit_task: "Create Analysis Task",
    previous: "Previous",
    last_step:
      "Your analysis task has been submitted, please enter the image analysis page to view the task progress.",
    notice1: "Please fill in Docker Image Name",
    notice2: "Please fill in Application Call Path",
    notice3: "Please complete all relevant information about the current Input",
    notice4: "Please fill in the path",
    notice5: 'Please select "Result Data Download Path" first.',
    notice6: 'Please select "Statistical Data Download Path" first.',
    notice7: "Please set the value for each parameter.",
    notice8: 'Please select "Image Data Type" first.',
    notice9: "Please select data.",
    notice10: 'Wrong input format of "Docker Image Name"',
    notice11: 'Wrong input format of "Custom File Mounting"',
    notice12: "Please fill in the VOI Edit information",
    notice13: "Age must be a positive number",
    notice14: "Please select gender",
    enable_text: "Application Enable",
    disable_text: "Application Disable",
    user_visible: "Users Visible",
    user_invisible: "Users Invisible",
    generic: "Generic",
    none: "无"
  },
  log: {
    account_type: "Account type",
    email: "Email",
    operator: "Operator",
    op_name: "Operate name",
    op_result: "Operate result",
    op_time: "Operate time",
  },
  notice: {
    p_confirm: "Please Confirm",
    modify_confirm: "Please Confirm",
    add_confirm: "Please Confirm",
    tips: "Tips",
    download: "Download",
    enable_ok: "Enable successfully.",
    enable_failed: "Enable failed.",
    disable_ok: "Disable successfully.",
    disable_failed: "Disable failed.",
    on_delete: "Deleting...",
    del_ok: "Delete successfully.",
    del_failed: "Delete failed.",
    on_save: "Saving...",
    save_ok: "Save successfully.",
    save_failed: "Save failed.",
    on_clear: "Clearing...",
    clear_ok: "Clear successfully.",
    clear_failed: "Clear failed.",
    select_tip: "Please select batch download data.",
    on_batch_download: "Batch downloading...",
    batch_download_ok: "Batch download successfully.",
    batch_download_failed: "Batch download failed.",
    on_download: "Downloading...",
    download_ok: "Download successfully.",
    download_failed: "Download failed.",
    on_submit: "Submitting...",
    submit_ok: "Submit successfully.",
    submit_failed: "Submit failed.",
    on_resubmit: "Resubmitting...",
    resubmit_ok: "Resubmit successfully.",
    resubmit_failed: "Resubmit failed.",
    add_tag_ok: "Add tags successfully.",
    add_tag_failed: "Add tags failed.",
    add_ok: "Add successfully.",
    add_failed: "Add failed.",
    packing: "Packing",
    pack_ok: "Pack successfully.",
    pack_failed: "Pack failed.",
    on_export: "Batch exporting logs...",
    export_ok: "Batch export logs successfully.",
    export_failed: "Batch export logs failed.",
    select_data: "Please select data.",
    select_group: "Please select group.",
    select_member: "Please select member.",
    no_upload_data: "Upload no data.",
    select_tag: "Please select tag",
    select_data_owner: "Please select data owner",
    select_data_upload: "Please select data for upload"
  },
  validate: {
    department: "The laboratory is required.",
    department_manager: "The laboratory manager is required.",
    email: "The email is required.",
    init_password: "The initial password is required.",
    password: "The password is required.",
    analysis: "The analysis number is required.",
    doctor: "The Chief Researcher is required.",
    student: "The Researcher is required.",
    data_owner: "The data owner is required.",
    tag_name: "The tag name is required.",
    project_name: "The project name is required.",
    project_owner: "The owner name is required.",
    patient_name: "The patient name is required.",
    age: "The age is required.",
    project_member: "The project members are required.",
    group: "The group is required.",
    tag: "The tags are required.",
    control_group: "The control group is required.",
    parameters: "Please select configuration parameters.",
    number: "Please enter numbers.",
    patient_group: "The patient group is required.",
    task_name: "The task name is required.",
    organization: "The organization name is required.",
    username: "The username is required.",
    name: "The name is required.",
    login_username: "The username is required.",
    login_password: "The password is required.",
  },
  task_status: {
    unsubmit: "Not submitted",
    created: "Submitted",
    wait: "Waiting",
    processing: "Processing",
    completed: "Completed",
    failed: "Failed",
    pause: "Pause",
    timeout: "Timeout",
    license_expired: "LICENSE EXPIRED",
    license_not_found: " LICENSE NOT FOUND",
    license_error: "LICENSE ERROR",
    invalid: "Invalid",
  },
  package_status: {
    processing: "Processing",
    completed: "Completed",
    failed: "Failed",
    failure_reason: "Failure Reason",
    no_dicom: "There Are No DICOM Files", //error_status = 2
    failed_niix: "Can not Unzip or Dicom Convert To Nifti Failed", //error_status = 1
  },
  copyright: {
    about: "About",
    company: "迈格生命科技(深圳)有限公司",
    version: "Version",
    date: "Date",
    terms: "License Terms",
    term_1: "Please note that use of this software is subject to the terms and conditions of the license agreement that accompanies the purchase of the software.",
    term_2: "You may not use the software without purchasing a valid license from Mindsgo or its authorized distributors."

  },
  ...enLocale,
};
