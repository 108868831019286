const dataManage = () => import("../views/dataManage/index");
const dataList = () => import("../views/dataManage/dataList");
const addSubject = () => import("../views/dataManage/addSubject");
const subjectDetail = () => import("../views/dataManage/subjectDetail");

export default {
    path: "/data-manage",
    name: "dataManage",
    meta: {
        icon: "iconfont iconshujuguanli",
        title: "数据管理",
        lang_key: "nav.data_manage",
        // authority: ["0", "1", "2", "3"]
    },
    component: dataManage,
    children: [
        {
            path: "/data-manage",
            redirect: "/data-manage/list"
        },
        {
            path: "/data-manage/list",
            name: "dataList",
            component: dataList,
            meta: {keepAlive: true}
        },
        {
            path: "/data-manage/add-subject",
            name: "addSubject",
            component: addSubject,
            meta: {keepAlive: false}
        },
        {
            path: "/data-manage/detail",
            name: "subjectDetail",
            component: subjectDetail,
            meta: {keepAlive: false}
        }
    ]
}