import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueI18n from "vue-i18n";

import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import locale from 'element-ui/lib/locale';
import VueCompositionApi from '@vue/composition-api'

Vue.use(VueCompositionApi)

Vue.use(ElementUI);
Vue.use(VueI18n);

import lang_en from "./language/lang-en";
import lang_zh from "./language/lang-zh";

import Helper from "./utils/helper";
Vue.prototype.$helper = Helper;

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "zh",
  messages: {
    zh: { ...lang_zh },
    en: { ...lang_en }
  }
});

locale.i18n((key, value) => i18n.t(key, value))

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");
