import axios from "axios";
import router from "../router.js";
import { Loading, Message } from 'element-ui'
import { send_request_get } from "../utils/utils.js";
const loginUrl = "/api/user/index/";
const summaryUrl = "/api/summary/index";
const dataUrl = "/api/project_subject/index/";
const dataDownloadUrl = "/api/download/index/";
const cascaderUserUrl = "/search/user/index";
const dataOwnerUrl = "/api/user_subject/index";
const voiViewUrl = "/voi/address/index";
const bodyPartUrl = "/api/body_part_examined/index";
const uploadParamsUrl = "/api/oss/index/";
const projectListUrl = "/api/project_list/index/";
const projectDataUrl = "/api/project/index/";
const projectGroupUrl = "/api/project_group/index/";
//const analyseUrl = "/api/brainlabel/index";
const analyseUrl = "/analysis/task_list/index";
const deleteAnalyseUrl = "/analysis/task_remove/index";
const downloadAnalysisDataUrl = "/analysis/task_download/index";
const tagsUrl = "/api/tags/index/";
const rulesUrl = "/api/prototype/index/";
const modalitycasUrl = "/api/prototype/index/";

const downloadUrl = "/api/download/index/";
const downloadVoiUrl = "/voi/download/index";

//"../../config/config.json";

let configJson = process.env.VUE_APP_CONFIG;
// process.env.VUE_APP_PLATFORM == "CCT"
//   ? process.env.VUE_APP_CONFIGCCT
//   : process.env.VUE_APP_CONFIG;

let backendConfig = send_request_get(configJson);
if (backendConfig != null || backendConfig != '') {
  backendConfig = JSON.parse(backendConfig);
}
//console.log('api.js backendconfig',backendConfig.backend_url)
localStorage.setItem("baseUrl", backendConfig.backend_url);

const service = axios.create({
  baseURL: backendConfig.backend_url || localStorage.baseURL, //api的baseurl
});

//请求拦截
service.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token") || "";
    let lang = localStorage.getItem("lang") || "zh";
    if (token) {
      config.headers["Authorization"] = token;
    }
    config.headers["Accept-Language"] = lang;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//响应拦截
service.interceptors.response.use(
  (response) => {
    if (response.data.status === 401) {
      if (response.data.message) {
        Message.error({
          message: response.data.message
        })
      }
      localStorage.removeItem("token");
      router.push("/login");
    }
    return Promise.resolve(response.data);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const login = (params) => {
  return service({
    method: "post",
    url: loginUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const summaryRequest = () => {
  return service({
    method: "post",
    url: summaryUrl,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const dataRequest = (params) => {
  return service({
    method: "post",
    url: dataUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const modalityRequest = () => {
  return service({
    method: "post",
    url: "/search/datasummary/index",
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const cascaderUserRequest = () => {
  return service({
    method: "post",
    url: cascaderUserUrl,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const dataOwnerRequest = (params) => {
  return service({
    method: "post",
    url: dataOwnerUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

//下载打包 旧接口 [已废弃]
export const dataDownload = (params) => {
  return service({
    method: "post",
    url: dataDownloadUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

//下载打包 新接口
export const downloadPackage = (params) => {
  return service({
    method: "post",
    url: downloadUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const getVoiViewAddress = (params) => {
  return service({
    method: "post",
    url: voiViewUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const bodyPartRequest = (params) => {
  return service({
    method: "post",
    url: bodyPartUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const getUploadParams = (params) => {
  return service({
    method: "post",
    url: uploadParamsUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const submitSubject = (params) => {
  return service({
    method: "post",
    url: dataUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const projectRequest = (params) => {
  return service({
    method: "post",
    url: projectListUrl,
    data: params,
  })
    .then((res) => {
      // if(res.status === 401) {
      //   location.href = "/login"
      // }
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const projectDataRequest = (params) => {
  return service({
    method: "post",
    url: projectDataUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const projectGroupRequest = (params) => {
  return service({
    method: "post",
    url: projectGroupUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const analyseRequest = (params) => {
  return service({
    method: "post",
    url: analyseUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const deleteAnalyse = (params) => {
  return service({
    method: "post",
    url: deleteAnalyseUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const downloadAnalysisData = (params) => {
  return service({
    method: "post",
    url: downloadAnalysisDataUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const tagsRequest = (params) => {
  return service({
    method: "post",
    url: tagsUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const ruleRequest = (params) => {
  return service({
    method: "post",
    url: rulesUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const getStudentList = (params) => {
  return service({
    method: "POST",
    url: "/api/student/student_list",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const addStudentRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/student/student_add",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const editStudentRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/student/student_update",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const deleteStudent = (params) => {
  return service({
    method: "POST",
    url: "/api/student/student_delete",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const studentLimits = (params) => {
  return service({
    method: "POST",
    url: "/api/student/limits_detail",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const setStudentAuthority = (params) => {
  return service({
    method: "POST",
    url: "/api/student/student_limits",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const doctorListRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/doctor/doctor_list",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const addDoctorRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/doctor/doctor_add",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const editDoctorRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/doctor/doctor_update",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const deleteDoctor = (params) => {
  return service({
    method: "POST",
    url: "/api/doctor/doctor_delete",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const doctorLimits = (params) => {
  return service({
    method: "POST",
    url: "/api/doctor/limits_detail",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const setDoctorAuthority = (params) => {
  return service({
    method: "POST",
    url: "/api/doctor/doctor_limits",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const departmentListRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/department/department_list",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const addDepartmentRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/department/department_add",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const editDepartmentRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/department/department_update",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const editDepartmentStatus = (params) => {
  return service({
    method: "POST",
    url: "/api/department/department_status",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const deleteDepartment = (params) => {
  return service({
    method: "POST",
    url: "/api/department/department_delete",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const departmentLimits = (params) => {
  return service({
    method: "POST",
    url: "/api/department/limits_detail",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const setDepartmentAuthority = (params) => {
  return service({
    method: "POST",
    url: "/api/department/department_limits",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const getApplication = (params = {}) => {
  return service({
    method: "POST",
    url: "/developer/app_list/index/",
    data: params
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const getApplicationDetail = (params) => {
  return service({
    method: "POST",
    url: "/developer/app_detail/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const getModalityCasRequest = (params) => {
  return service({
    method: "POST",
    url: modalitycasUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const addApplication = (params) => {
  return service({
    method: "POST",
    url: "/developer/app_create/index/",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const editApplication = (params) => {
  return service({
    method: "POST",
    url: "/developer/app_edit/index/",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const delApplicationRequest = (params) => {
  return service({
    method: "POST",
    url: "/developer/app_remove/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const createTaskRequest = (params) => {
  return service({
    method: "POST",
    url: "/analysis/task_create/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

export const logRequest = (params) => {
  return service({
    method: "POST",
    url: "/api/oplog/oplog_list",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

//项目管理：批量下载相关接口
//项目中分析应用
export const projectAppTasksRequest = (params) => {
  return service({
    method: "POST",
    url: "/projects/app_tasks/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};
//项目的分析任务
export const projectTasksRequest = (params) => {
  return service({
    method: "POST",
    url: "/projects/group_tasks/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

//项目提交批量打包接口
export const projectCreatePackRequest = (params) => {
  return service({
    method: "POST",
    url: "/projects/create_pack/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};


//获取打包任务
export const projectPackingRequest = (params) => {
  return service({
    method: "POST",
    url: "/pack/lists/index", //"/projects/packing/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};
//删除打包任务
export const removePackage = (params) => {
  return service({
    method: "POST",
    url: "/pack/remove/index", //"/projects/packing/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

//批量提交分析任务

export const batchCreateTaskRequest = (params) => {
  return service({
    method: "POST",
    url: "/analysis/task_batch_create/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};

//获取项目中某个分析应用的序列数据


export const projectAppFiles = (params) => {
  return service({
    method: "POST",
    url: "/projects/app_files/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};



//批量修改序列类型

export const seriesTypeEdit = (params) => {
  return service({
    method: "POST",
    url: "/api/series/index",
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};


//下载voi编辑结果
export const downloadVoiResult = (params) => {
  return service({
    method: "post",
    url: downloadVoiUrl,
    data: params,
  })
    .then((res) => {
      return res;
    })
    .catch((error) => Promise.reject(error));
};
