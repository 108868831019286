import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";

export default new Vuex.Store({
  state: {
    language: localStorage.getItem("lang") || "cn",
    userid: user ? user.id : "",
    user: user ? user : {},
    username: user ? user.realname : "",
    url_adaptorapi: "",
    url_backendapi: "", //后端域名地址 http://v2.cafe.brainlabel.org
    url_dicomviewer: "", //dicom 阅片 'http://dicom.viewer.brainlabel.org/#/'
    url_t1viewer: "", //3D 阅片
    url_voiviewer: "", //voi
    backendConfig: null,
    dataParams: {
      "request": "listallsubjects",
      "userid": "1",
      "limit": 15,
      "offset": 0,
      "search": "",
      "search_number": "",
      "search_gender": "",
      "search_modality":
        [],
      "search_series":
        [],
      "search_tag":
        [],
      "search_age_min": "",
      "search_age_max": "",
      "search_createdate_min": "",
      "search_createdate_max": "",
      "search_studydate_min": "",
      "search_studydate_max": ""
    }, //数据列表页的请求参数，每次点击搜索、高级搜索、翻页时记录；以方便进入数据详情页后返回时使用；
    analysisParams: { "search": "", "series": "", "modality": "", "subject_id": "", "app_id": "", "limit": 15, "offset": 0 }//分析列表请求参数，以方便进入数据详情页后返回时使用；
  },
  mutations: {
    selectLang(state, lang) {
      state.language = lang;
    },
    setUserId(state, userid) {
      state.userid = userid;
    },
    setUserName(state, username) {
      state.username = username
    },
    setAdaptorapi(state, url) {
      state.url_adaptorapi = url
    },
    setBackendapi(state, url) {
      state.url_backendapi = url
    },
    setDicomviewer(state, url) {
      state.url_dicomviewer = url + '/#/'
    },
    setT1viewer(state, url) {
      state.url_t1viewer = url
    },
    setVOIviewer(state, url) {
      state.url_voiviewer = url
    },
  },
  actions: {
    setUserIdAsync(context, userid) {
      context.commit("setUserId", userid);
    },
    setUserNameAsync(context, username) {
      context.commit("setUserName", username);
    },
  }
});
