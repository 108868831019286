<template>
  <div>
    <div
      @click="handleDrawer"
      class="
        downloadlist
        border-radius
        rounded
        shadow
        d-flex
        justify-content-between
        px-3
        py-2
        cursor
      "
      v-if="isShow"
    >
      <div>
        <i class="el-icon-download text-success"></i>
        {{ $t("_data.download_list") }}:{{ array_result.length }}
        <!-- 正在打包:{{ array_process.length }} -->
      </div>
      <div>
        <span v-if="drawer">{{ $t("collapse") }}</span
        ><span v-else>{{ $t("expand") }}</span>
        <i class="el-icon-s-unfold ms-1 text-success inline-block"></i>
      </div>
    </div>

    <el-drawer
      :title="$t('_data.download_list')"
      :visible.sync="drawer"
      size="48%"
    >
      <div class="pb-3 mb-3">
        <el-table :data="array_result" style="width: 100%">
          <el-table-column prop="file_name" :label="$t('name')">
            <template v-slot="scope">
              <i class="el-icon-collection-tag"></i>
              <small> {{ formatType(scope.row.data_type) }}</small>
              <br />
              <span v-if="scope.row.status == '1' || scope.row.status == '4'">
                <small>
                  <a
                    class="cursor btn btn-success btn-sm mt-2 text-light"
                    @click="iframDownload(scope.row.download_url)"
                    :title="scope.row.file_name"
                  >
                    <i class="el-icon-download"></i>
                    {{ $t("_data.download") }}</a
                  ></small
                ></span
              >
              <span v-else>
                <i class="el-icon-tickets"></i>
                {{ scope.row.file_name || $t("_data.packing") }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="date_created" :label="$t('time')" width="220">
            <template v-slot="scope">
              <small>
                <span>{{ $t("s_time") }}: {{ scope.row.date_created }}</span>
                <br />
                <span>{{ $t("u_time") }}: {{ scope.row.date_update }}</span>
              </small>
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template v-slot="scope">
              <p class="text-center">
                <span class="badge">{{ formatStatus(scope.row.status) }}</span>
              </p>
              <el-popconfirm
                :title="$t('notice.p_confirm')"
                :confirm-button-text="$t('confirm')"
                :cancel-button-text="$t('cancel')"
                @confirm="remove_package(scope.row.id)"
              >
                <span
                  slot="reference"
                  class="text-primary cursor text-center d-block"
                >
                  <small>
                    <i class="el-icon-delete"></i> {{ $t("delete") }}</small
                  >
                </span>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { eventHub } from "@/eventHub.js";
import { projectPackingRequest, removePackage } from "@/api/api";
export default {
  data() {
    return {
      drawer: false,
      interval: null,
      notify: null,
      message: "",
      packingTotal: 0,
      taskTimer: 13000,
      array_process: [],
      array_result: [],
      isShow: false,
    };
  },
  beforeCreate() {},
  created() {
    setTimeout(() => {
      if (this.$router.history.current.fullPath == "/login") {
        this.isShow = false;
      } else {
        this.isShow = true;
        this.pollData();
      }
    }, 100);
  },
  updated() {},
  beforeDestroy() {
    //clearInterval(this.interval);
    this.clearTimer();
    this.packingTotal = 0;
    this.drawer = false;
  },
  mounted() {
    eventHub.$on("getProcessList", (isOpenDrawer = true) => {
      //console.log("isOpenDrawer", isOpenDrawer);
      this.drawer = isOpenDrawer;
      this.pollData();
      return;
    });

    eventHub.$on("closeProcessList", () => {
      //console.log("isOpenDrawer closeProcessList", this.packingTotal);
      this.packingTotal = 0;
      this.drawer = false;
      this.isShow = false;
      return;
    });
  },
  watch: {
    packingTotal(newVal, oldVal) {
      //console.log("watch packingTotal", newVal, oldVal);
      if (newVal == 0) {
        this.clearTimer();
      }
    },
    array_process(n, o) {
      if (!n.length) this.clearTimer();
    },
    drawer(n, o) {
      if (n) {
        this.timedRefresh();
      } else {
        this.clearTimer();
      }
    },
  },
  methods: {
    formatType(datatype) {
      //statistic or result download_dicom download_file_nifti voi_file voi_brainlabel download_file_dicom
      const me = this;
      switch (datatype) {
        case "statistic":
          return me.$t("_data.data_statistic");
          break;

        case "result":
          return me.$t("_data.data_result");
          break;

        case "download_file_dicom":
          return me.$t("_data.data_dicom");
          break;

        case "download_dicom":
          return me.$t("_data.data_dicom");
          break;

        case "download_nifti":
          return me.$t("_data.data_nifti");
          break;

        case "download_file_nifti":
          return me.$t("_data.data_nifti");
          break;
        case "batch_download_subject_file":
          return (
            me.$t("_data.data_nifti") +
            "(" +
            me.$t("_data.patient_det") +
            me.$t("batch") +
            ")"
          );
          break;
        case "batch_download_subject_list":
          return (
            me.$t("_data.data_nifti") +
            "(" +
            me.$t("_data.manage") +
            me.$t("batch") +
            ")"
          );

        case "voi_file":
          return (
            me.$t("_data.data_voi") + "(" + me.$t("_data.patient_det") + ")"
          );
          break;

        case "voi_brainlabel":
          return me.$t("_data.data_voi") + "(" + me.$t("_data.service") + ")";
          break;

        default:
          break;
      }
    },
    formatStatus(status) {
      switch (status) {
        case "0":
          return this.$t("notice.packing");
        case "1":
          return this.$t("notice.pack_ok");
        case "2":
          return this.$t("notice.pack_failed");
        case "3":
          return this.$t("notice.packing");
        case "4":
          return this.$t("notice.pack_ok");
        default:
          return "";
      }
    },
    formatClass(status) {
      switch (status) {
        case "0":
          return this.$t("bg-light");
        case "1":
          return this.$t("bg-success text-light");
        case "2":
          return this.$t("bg-danger text-light");
        case "3":
          return this.$t("bg-light");
        case "4":
          return this.$t("bg-success text-light");
        default:
          return "";
      }
    },
    handleDrawer() {
      this.drawer = !this.drawer;
      //this.drawer ? this.pollData() : "";
      this.pollData();
    },
    pollData() {
      this.getProcessList();
    },
    timedRefresh() {
      this.interval = setInterval(() => {
        this.getProcessList();
      }, 5000);
    },
    clearTimer() {
      if (this.interval) clearInterval(this.interval);
      this.interval = null;
    },
    async getProcessList() {
      if (typeof localStorage.token == "undefined" || !localStorage.token) {
        this.packingTotal = 0;
        return;
      }
      //每10秒请求一次正在打包的记录
      let params = {};

      try {
        let res = await projectPackingRequest(params);
        if (res.status === 200) {
          let total = res.total;
          this.array_process = res.array_process;
          this.array_result = res.array_result;
          this.packingTotal = total;
          this.message = res.message;
          this.isShow = true;
        } else {
          this.packingTotal = 0;
          this.isShow = false;
        }
      } catch (error) {
        console.log(error);
        this.packingTotal = 0;
        this.isShow = false;
      }
    },
    iframDownload(url) {
      const me = this;
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.style.height = 0;
      iframe.src = url;
      document.body.appendChild(iframe);
      setTimeout(() => {
        iframe.remove();
      }, me.taskTimer);
    },
    async remove_package(id) {
      let params = { id: id };
      try {
        let res = await removePackage(params);
        if (res.status === 200) {
          this.$helper.successNotify(res.message);
          this.packingTotal--;
          this.array_result = this.array_result.filter((r) => r.id != id);
        } else {
          this.$helper.errNotify(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.downloadlist {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 10012;
  background: rgb(255, 255, 255);
  min-width: 280px;
  animation: download_bgcolor 1s 1;
  border-right: 4px solid #27a243;
}

@keyframes download_bgcolor {
  0% {
    background-color: #fff;
  }

  60% {
    background-color: #8efca7;
  }

  100% {
    background-color: #fff;
  }
}
</style>