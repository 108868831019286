<template>
  <div id="app">
    <router-view />
    <DownloadList />
  </div>
</template>

<script>
import { send_request_get } from "@/utils/utils.js";

import DownloadList from "@/components/download/list.vue";

let configJson = process.env.VUE_APP_CONFIG;
// process.env.VUE_APP_PLATFORM == "CCT"
//   ? process.env.VUE_APP_CONFIGCCT
//   : process.env.VUE_APP_CONFIG;
export default {
  components: {
    DownloadList,
  },
  data() {
    return {
      interval: null,
      notify: null,
      message: "",
      packingTotal: 0,
      taskTimer: 13000,
    };
  },
  // beforeCreate() {
  //   let env_logo_name = process.env.VUE_APP_LOGO;
  //   console.log("env_logo_name", env_logo_name);
  // },
  created() {
    //let config_json = configJson();
    let backendConfig = JSON.parse(send_request_get(configJson));

    this.$store.commit("setBackendapi", backendConfig.backend_url);
    this.$store.commit("setAdaptorapi", backendConfig.adaptor_frontend_url);
    this.$store.commit("setDicomviewer", backendConfig.dcmviewer_url);
    this.$store.commit("setT1viewer", backendConfig.t1viewer_url);
    this.$store.commit("setVOIviewer", backendConfig.voieditor_url);
    //this.getProcessList();
    //this.pollData();
    this.$store.state.backendConfig = backendConfig;
  },
  beforeDestroy() {
    //clearInterval(this.interval);
    //this.clearTimer();
  },
  mounted() {
    // eventHub.$on("getProcessList", () => {
    //   this.closeNotify();
    //   this.clearTimer();
    //   this.pollData();
    //   return;
    // });
  },
  watch: {
    // packingTotal(newVal, oldVal) {
    //   //console.log("watch packingTotal", newVal, oldVal);
    //   if (newVal == 0) {
    //     this.closeNotify();
    //     clearInterval(this.interval);
    //   }
    // },
  },
  methods: {
    //   pollData() {
    //     // this.interval = setInterval(() => {
    //     //   this.getProcessList();
    //     // }, 5000);
    //     this.getProcessList();
    //   },
    //   clearTimer() {
    //     if (this.interval) clearInterval(this.interval);
    //     this.interval = null;
    //   },
    //   async getProcessList() {
    //     if (typeof localStorage.token == "undefined" || !localStorage.token) {
    //       this.closeNotify();
    //       return;
    //     }
    //     //每10秒请求一次正在打包的记录
    //     let params = {};
    //     try {
    //       let res = await projectPackingRequest(params);
    //       if (res.status === 200) {
    //         let total = res.total;
    //         // if (res.result_download.length) {
    //         //   res.result_download.forEach((d) => {
    //         //     //window.location.href = d.download_url;
    //         //     this.iframDownload(d.download_url);
    //         //   });
    //         // }
    //         if (total != this.packingTotal || res.message != this.message) {
    //           this.closeNotify();
    //         }
    //         this.packingTotal = total;
    //         if (total == 0) {
    //           this.cleanFetch();
    //           return;
    //         }
    //         this.message = res.message;
    //         this.openNotify();
    //       } else {
    //         this.clearTimer();
    //       }
    //     } catch (error) {
    //       console.log(error);
    //       this.cleanFetch();
    //     }
    //   },
    //   cleanFetch() {
    //     this.clearTimer();
    //     this.packingTotal = 0;
    //     this.message = "";
    //     this.closeNotify();
    //   },
    //   openNotify() {
    //     if (this.notify != null) return;
    //     this.notify = this.$notify({
    //       title: this.message,
    //       position: "bottom-right",
    //       duration: 0, // 一直显示
    //       showClose: false, //隐藏关闭按钮
    //       //iconClass: "el-icon-warning",
    //       type: "success",
    //       customClass: "downloadNotify",
    //     });
    //   },
    //   closeNotify() {
    //     const me = this;
    //     if (me.notify != null) {
    //       me.notify.close();
    //       me.notify = null;
    //     }
    //   },
    //   iframDownload(url) {
    //     const me = this;
    //     const iframe = document.createElement("iframe");
    //     iframe.style.display = "none";
    //     iframe.style.height = 0;
    //     iframe.src = url;
    //     document.body.appendChild(iframe);
    //     setTimeout(() => {
    //       iframe.remove();
    //     }, me.taskTimer);
    //   },
  },
};
</script>

<style>
@import "./style/font_1383472_mbehim3e5i.css";
@import "./style/fontawesome/css/all.css";
@import "./style/animate.css";
@import "./style/bootstrap.min.css";
@import "./style/bootstrap-utilities.css";
@import "./style/common.css";

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}
</style>
