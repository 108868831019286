import { Message, Notification } from "element-ui";
export default {
  formatPrice(value, n) {
    n = n > 0 && n <= 2 ? n : 2;
    value = parseFloat((value + "").replace(/[^\d.-]/g, "")).toFixed(n) + "";
    let l = value
      .split(".")[0]
      .split("")
      .reverse(),
      r = value.split(".")[1];
    let t = "";
    for (var i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? "," : "");
    }
    return (
      t
        .split("")
        .reverse()
        .join("") +
      "." +
      r
    );
  },
  successMessage(message, callback) {
    Message({
      type: "success",
      message: message,
      center: true,
      onClose: callback
    });
  },
  errorMessage(message) {
    Message({
      type: "error",
      message: message,
      center: true,
      duration: 3000
    });
  },
  netErrorMessage(err) {
    Message({
      type: "error",
      message: err && err.message ? err.message : "网络错误，请检查网络设置！"
    });
  },
  warningMessage(message) {
    Message({
      type: "warning",
      message: message,
      center: true
    });
  },
  errNotify(title, msg) {
    Notification.error({
      title: title,
      message: msg || "",
      duration: 3000
    });
  },
  successNotify(title, callBack, duration) {
    Notification.success({
      title: title,
      message: "",
      duration: 5000,
      onClose: callBack || function () { }
    });
  },
  warnNotify(title, msg) {
    Notification.warning({
      title: title,
      message: msg || "",
      duration: 7000
    });
  },
  trim(str) {
    if (str === null || str === undefined) {
      return "";
    } else {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    }
  },
  formatGender: function (row) {
    return row.subject_gender === "1"
      ? "女"
      : row.subject_gender === "0"
        ? "男"
        : "";
  },
  formatAgeUnit: function (unit) {
    switch (unit) {
      case "Y":
        return "_data.year";
      case "M":
        return "_data.month";
      case "W":
        return "_data.week";
      case "D":
        return "_data.day";
      default:
        return "";
    }
  },
};
