import Vue from "vue";
import Router from "vue-router";
import BasicLayout from "./layouts/basicLayout.vue";

Vue.use(Router);

const routerList = [];
function importAll(r) {
  r.keys().forEach(key => {
    routerList.push(r(key).default)
  })
}

//第一个参数：目标文件夹
//参数二：是否查找子集
//参数三： 正则匹配
importAll(require.context("./router", true, /\.routes\.js/))

const login = () => import("./views/login");
const register = () => import("./views/sign/register");
const resetPassword = () => import("./views/sign/resetPassword");
const newPassword = () => import("./views/sign/newPassword");
const changePassword = () => import("./views/sign/changePassword");

const summary = () => import("./views/summary/index");
const summaryList = () => import("./views/summary/summaryList");

// const dataManage = () => import("./views/dataManage/index");
// const dataList = () => import("./views/dataManage/dataList");
// const addSubject = () => import("./views/dataManage/addSubject");
// const subjectDetail = () => import("./views/dataManage/subjectDetail");

const MRIAnalyseList = () => import("./views/MRIAnalyse/MRIAnalyseList");

const PETAnalyseList = () => import("./views/PETAnalyse/PETAnalyseList");

const department = () => import("./views/department/index");
const departmentList = () => import("./views/department/departmentList");
const departmentAuthority = () => import("./views/department/authority");
const doctor = () => import("./views/doctor/index");
const doctorList = () => import("./views/doctor/doctorList");
const doctorAuthority = () => import("./views/doctor/authority");
const student = () => import("./views/student/index");
const studentList = () => import("./views/student/studentList");
const studentAuthority = () => import("./views/student/authority");

const application = () => import("./views/application/index");
const applicationList = () => import("./views/application/application");
const applicationEdit = () => import("./views/application/editApplication");
const tagsList = () => import("./views/tagsManage/tagsList");
const ruleAndSeries = () => import("./views/ruleManage/ruleAndSeries");
const toolList = () => import("./views/toolDownload/toolList");
const logList = () => import("./views/logList");

const personalInfo = () => import("./views/personal/personalInfo");

var router = new Router({
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      hideInMenu: true,
      redirect: "/login"
    },
    {
      path: "/login",
      hideInMenu: true,
      component: login
    },
    {
      path: "",
      component: BasicLayout,
      children: [
        {
          path: "/",
          redirect: "/data-manage"
        },
        {
          path: "/summary",
          name: "summary",
          meta: {
            icon: "iconfont iconzhaiyao",
            title: "摘要",
            lang_key: "nav.summary",
          },
          component: summary,
          children: [
            {
              path: "/summary",
              redirect: "/summary/list"
            },
            {
              path: "/summary/list",
              name: "summaryList",
              component: summaryList
            },
            {
              path: "/summary/subject-files",
              name: "subjectFiles",
              component: summary
            }
          ]
        },
        ...routerList,
        // {
        //   path: "/data-manage",
        //   name: "dataManage",
        //   meta: {
        //     icon: "fa fa-dashboard fa-tachometer-alt",
        //     title: "数据管理",
        //     lang_key: "nav.data_manage",
        //     authority: ["1", "2"]
        //   },
        //   component: dataManage,
        //   children: [
        //     {
        //       path: "/data-manage",
        //       redirect: "/data-manage/list"
        //     },
        //     {
        //       path: "/data-manage/list",
        //       name: "dataList",
        //       component: dataList
        //     },
        //     {
        //       path: "/data-manage/add-subject",
        //       name: "addSubject",
        //       component: addSubject
        //     },
        //     {
        //       path: "/data-manage/detail",
        //       name: "subjectDetail",
        //       component: subjectDetail
        //     }
        //   ]
        // },
        {
          path: "/mri-analysis",
          name: "mriAnalysis",
          meta: {
            icon: "iconfont iconMRIfenxi",
            title: "MRI分析",
            lang_key: "nav.MRI_analysis",
            department_limits: { analysis: "disable" }
          },
          component: MRIAnalyseList
        },
        // {
        //   path: "/pet-analysis",
        //   name: "petAnalysis",
        //   meta: {
        //     icon: "iconfont iconPETfenxi",
        //     title: "PET分析",
        //     lang_key: "nav.PET_analysis",
        //   },
        //   component: PETAnalyseList
        // },
        {
          path: "/department",
          name: "department",
          meta: {
            icon: "iconfont iconbumenguanli",
            title: "部门管理",
            lang_key: "nav.department",
            authority: ["0"]
          },
          component: department,
          children: [
            {
              path: "/department",
              redirect: "/department/list"
            },
            {
              path: "/department/list",
              name: "departmentList",
              component: departmentList
            },
            {
              path: "/department/authority",
              name: "departmentAuthority",
              component: departmentAuthority
            },
          ]
        },
        {
          path: "/doctor",
          name: "doctor",
          meta: {
            icon: "iconfont iconyishengguanli",
            title: "医生管理",
            lang_key: "nav.doctor",
            authority: ["0", "1"]
          },
          component: doctor,
          children: [
            {
              path: "/doctor",
              redirect: "/doctor/list"
            },
            {
              path: "/doctor/list",
              name: "doctorList",
              component: doctorList
            },
            {
              path: "/doctor/authority",
              name: "doctorAuthority",
              component: doctorAuthority
            },
          ]
        },
        {
          path: "/student",
          name: "student",
          meta: {
            icon: "iconfont iconxueshengguanli",
            title: "学生管理",
            lang_key: "nav.student",
            authority: ["0", "1", "2"]
          },
          component: student,
          children: [
            {
              path: "/student",
              redirect: "/student/list"
            },
            {
              path: "/student/list",
              name: "studentList",
              component: studentList
            },
            {
              path: "/student/authority",
              name: "studentAuthority",
              component: studentAuthority
            },
          ]
        },
        {
          path: "/application",
          name: "application",
          meta: {
            icon: "iconfont iconfuwuyingyong",
            title: "服务应用",
            lang_key: "nav.service",
            department_limits: { analysis: "disable" }
          },
          component: application,
          children: [
            {
              path: "/application",
              redirect: "/application/list"
            },
            {
              path: "/application/list",
              name: "applicationList",
              component: applicationList
            },
            {
              path: "/application/edit",
              name: "applicationEdit",
              component: applicationEdit
            },
          ]
        },
        {
          path: "/rule-manage",
          name: "ruleManage",
          meta: {
            icon: "iconfont iconguizeguanli",
            title: "规则管理",
            lang_key: "nav.rule",
          },
          component: ruleAndSeries
        },
        {
          path: "/tag-manage",
          name: "tagManage",
          meta: {
            icon: "iconfont iconbiaoqianguanli",
            title: "标签管理",
            lang_key: "nav.tag_manage",
            authority: ["2", "3"],
          },
          component: tagsList
        },
        {
          path: "/log-list",
          name: "logList",
          meta: {
            icon: "iconfont icongongjuxiazai",
            title: "操作日志",
            lang_key: "nav.log",
            authority: ["0"]
          },
          component: logList
        },
        {
          path: "/personal-info",
          name: "personalInfo",
          meta: {
            icon: "iconfont icongerenxinxi",
            title: "个人信息",
            lang_key: "nav.user_info",
          },
          component: personalInfo
        },
        {
          path: "/sign/change-password",
          name: "changePassword",
          meta: {
            icon: "iconfont iconxiugaimima",
            title: "修改密码",
            lang_key: "nav.reset_password",
          },
          component: changePassword
        },
      ]
    },
    {
      path: "/sign/register",
      name: "register",
      hideInMenu: true,
      component: register
    },
    {
      path: "/sign/reset-password",
      name: "resetPassword",
      hideInMenu: true,
      component: resetPassword
    },
    {
      path: "/sign/new-password",
      name: "newPassword",
      hideInMenu: true,
      component: newPassword
    },
    {
      path: "*",
      name: "404",
      hideInMenu: true,
      component: login
    }
  ]
});

router.beforeEach((to, from, next) => {
  // const token = localStorage.getItem("token");
  next();
});

export default router;
