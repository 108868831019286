export function parseQueryString(url) {
  let quora = url.indexOf("?");
  let lens = url.length;
  let paramstring = url.substr(quora + 1, lens);
  const params = new Map(paramstring.split("&").map(param => param.split("=")));
  return params;
}
//生成唯一字符串
export function uniqid(a = "", b = false) {
  var c = Date.now() / 1000;
  var d = c.toString(16).split(".").join("");
  while (d.length < 14) {
    d += "0";
  }
  var e = "";
  if (b) {
    e = ".";
    var f = Math.round(Math.random() * 100000000);
    e += f;
  }
  return a + d + e;
}

//获取文件名后缀：.zip
export function getsuffix(filename) {
  let pos = filename.lastIndexOf('.');
  let suffix = '';
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
}

export function send_request_get(url) {
  var xmlhttp = null;
  if (window.XMLHttpRequest) {
    xmlhttp = new XMLHttpRequest();
  } else if (window.ActiveXObject) {
    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");
  }
  if (xmlhttp != null) {
    xmlhttp.open("GET", url, false);
    // ////console.log('send_request:', url);
    xmlhttp.send(null);

    return xmlhttp.responseText;
  } else {
    console.log("Your browser does not support XMLHTTP.");
  }
}

// export function configJson() {
//   //return '../../config/config.json';
//   //return 'http://www.b.com/config/config.json';
//   //return 'http://192.168.1.228:28801/config/config.json';
// }